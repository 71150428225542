// extracted by mini-css-extract-plugin
export var root = "PlasmicHeader-module--root--1d_HU";
export var component1 = "PlasmicHeader-module--component1--1UeDB";
export var santedLogoSmallerJan20211 = "PlasmicHeader-module--santedLogoSmallerJan20211--Vq1tR";
export var santedLogoSmallerJan20211__white = "PlasmicHeader-module--santedLogoSmallerJan20211__white--1wuGE";
export var rectangle1 = "PlasmicHeader-module--rectangle1--_K9xA";
export var productsTab1 = "PlasmicHeader-module--productsTab1--2Qv10";
export var productsTab1__white = "PlasmicHeader-module--productsTab1__white--1cTYY";
export var productsTab2 = "PlasmicHeader-module--productsTab2--qcEpO";
export var productsTab2__white = "PlasmicHeader-module--productsTab2__white--MfJLH";
export var productsTab3 = "PlasmicHeader-module--productsTab3--1gEbk";
export var productsTab3__white = "PlasmicHeader-module--productsTab3__white--14cOb";