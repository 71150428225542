// extracted by mini-css-extract-plugin
export var root = "PlasmicInquiryForm-module--root--d96z5";
export var freeBox__xx54 = "PlasmicInquiryForm-module--freeBox__xx54--1LLuP";
export var freeBox___8WCqB = "PlasmicInquiryForm-module--freeBox___8WCqB--vJt-B";
export var freeBox___6NqAu = "PlasmicInquiryForm-module--freeBox___6NqAu--VJQmO";
export var firstName = "PlasmicInquiryForm-module--firstName--LfrNE";
export var textbox___1Dy5R = "PlasmicInquiryForm-module--textbox___1Dy5R--21jco";
export var freeBox__qJf8A = "PlasmicInquiryForm-module--freeBox__qJf8A--1gRMg";
export var freeBox__yk6Pb = "PlasmicInquiryForm-module--freeBox__yk6Pb--iAGfF";
export var lastName = "PlasmicInquiryForm-module--lastName--30z7y";
export var textbox__kiCh = "PlasmicInquiryForm-module--textbox__kiCh--3UAHF";
export var freeBox__iAJ0 = "PlasmicInquiryForm-module--freeBox__iAJ0--Khw24";
export var freeBox__h6HBp = "PlasmicInquiryForm-module--freeBox__h6HBp--2bSCE";
export var freeBox__sdgL = "PlasmicInquiryForm-module--freeBox__sdgL--noFHG";
export var textbox__xdRKw = "PlasmicInquiryForm-module--textbox__xdRKw--1ZF6h";
export var freeBox__ekTln = "PlasmicInquiryForm-module--freeBox__ekTln--2xqzO";
export var freeBox__n0ZpS = "PlasmicInquiryForm-module--freeBox__n0ZpS--IoHoL";
export var freeBox___3OWu5 = "PlasmicInquiryForm-module--freeBox___3OWu5--3WAYV";
export var textarea = "PlasmicInquiryForm-module--textarea--2beGQ";
export var button = "PlasmicInquiryForm-module--button--mATmi";