// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import * as React from "react"
import * as p from "@plasmicapp/react-web"
export type UnnamedGlobalVariantGroupValue = "unnamedVariant"
export const UnnamedGlobalVariantGroupContext = React.createContext<
  UnnamedGlobalVariantGroupValue | undefined
>("PLEASE_RENDER_INSIDE_PROVIDER" as any)

export function useUnnamedGlobalVariantGroup() {
  return React.useContext(UnnamedGlobalVariantGroupContext)
}

export default UnnamedGlobalVariantGroupContext
/* prettier-ignore-end */
