// extracted by mini-css-extract-plugin
export var root = "PlasmicProducts-module--root--2IyjY";
export var header = "PlasmicProducts-module--header--2jg2d";
export var li__arGl7 = "PlasmicProducts-module--li__arGl7--WKruQ";
export var img___031M8 = "PlasmicProducts-module--img___031M8--3oduv";
export var img__lm03Z = "PlasmicProducts-module--img__lm03Z--3CnQj";
export var img__xphwd = "PlasmicProducts-module--img__xphwd--3D5uu";
export var img__gJIcu = "PlasmicProducts-module--img__gJIcu--2Iryz";
export var img__a2OMk = "PlasmicProducts-module--img__a2OMk--26wot";
export var li___1CueT = "PlasmicProducts-module--li___1CueT--37XNS";
export var img__dVlb2 = "PlasmicProducts-module--img__dVlb2--2RLB1";
export var img__icSe6 = "PlasmicProducts-module--img__icSe6--c7uL0";
export var img__d5JSk = "PlasmicProducts-module--img__d5JSk--a-d9-";
export var img__kfRmh = "PlasmicProducts-module--img__kfRmh--3NnMe";
export var img__udb3Q = "PlasmicProducts-module--img__udb3Q--1SE8o";
export var allProducts = "PlasmicProducts-module--allProducts--1hQJZ";