// extracted by mini-css-extract-plugin
export var root = "PlasmicHeaderWhite-module--root--1_K46";
export var component1 = "PlasmicHeaderWhite-module--component1--3Z6c4";
export var santedLogoSmallerJan20211 = "PlasmicHeaderWhite-module--santedLogoSmallerJan20211--1UgUB";
export var santedLogoSmallerJan20211__white = "PlasmicHeaderWhite-module--santedLogoSmallerJan20211__white--3nuPv";
export var rectangle1 = "PlasmicHeaderWhite-module--rectangle1--3FJqp";
export var productsTab1 = "PlasmicHeaderWhite-module--productsTab1--1EZbA";
export var productsTab1__white = "PlasmicHeaderWhite-module--productsTab1__white--3WQfU";
export var productsTab2 = "PlasmicHeaderWhite-module--productsTab2--3mVd6";
export var productsTab2__white = "PlasmicHeaderWhite-module--productsTab2__white--1sRYk";
export var productsTab3 = "PlasmicHeaderWhite-module--productsTab3--3h0BV";
export var productsTab3__white = "PlasmicHeaderWhite-module--productsTab3__white--3Q82J";